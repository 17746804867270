import React, { FC, ReactEventHandler, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

type SafeNumber = number | `${number}`

type CustomImageProps = {
  width?: SafeNumber;
  height?: SafeNumber;
  backgroundColor?: string;
  src: string;
  objectFit?: 'fill' | 'cover' | 'contain' | 'scale-down';
  alt: string;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  onError?: ReactEventHandler<HTMLImageElement>;
  onClick?: ReactEventHandler<HTMLImageElement>;
  priority?: boolean;
  quality?: number;
  placeholder?: 'blur' | 'empty';
  className?: string;
  isUnoptimized?: boolean;
  layout?: string;
  sizes?: string;
  checktimeout?: boolean;
};


export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

const CustomImageContainer = styled.div`
  position: relative;
  width: auto;
  & > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    &.loaded {
      display: block;
    }
  }
  &::before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(lightgray 200px, transparent 0);
    background-repeat: repeat-y;
    background-size: 100%;
    background-position: 0px 0px;
    animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  }
`;

const normalizeSrc = (src) => {
  return src[0] === '/' ? src.slice(1) : src;
};

const cloudflareLoader = ({ src, width, quality }) => {
  if (src.endsWith('.gif')) {
    //  gif 확장자의 경우 최적화 하지 않음
    return src;
  }
  return `https://www.acon3d.com/cdn-cgi/image/f=webp,w=${width},q=${quality || 85}/${normalizeSrc(src)}`;
};

const rangedWidth = (width) => {
  const responsive = [320, 600, 836, 1200, 1920];
  for (let v of responsive) {
    if (width <= v) return v;
  }
  return responsive[responsive.length - 1];
};


export const CustomImage: FC<CustomImageProps> = (props) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const { src, width, height, backgroundColor, className, isUnoptimized: unoptimizedFromProps = false, sizes, ...rest } = props;

  const [unoptimized, setUnoptimized] = useState(unoptimizedFromProps
    || props.src.startsWith('/imgs')
    || props.src.endsWith('gif')
    || (!src.includes('.acon3d.com') && !src.includes('.cloudfront.net')));

  // if (!rest.layout) {
  //   rest.layout = 'fill';
  // }

  const style: any = {
    position: 'relative',
    height,
  };

  let timeout = null;
  useEffect(() => {
    if (!imgRef?.current || !props.checktimeout) return;
    const img = imgRef.current;
    timeout = setTimeout(function() {
      if (!img.complete) { // @ts-ignore
        props.onError && props.onError({ target: img });
        img.setAttribute('data-timedout', 'true');
      }
    }, 1000);
    return ()=> {
      clearTimeout(timeout);
    };
  }, [imgRef.current]);

  if (!props?.src) {
    return <div style={{
      width,
    }} className="custom-img"></div>;
  }

  // /imgs/...
  // 해당 프로젝트에 포함해서 static file 로 서빙하는 이미지들의 경우에는,
  // 로컬 & 스테이징 환경 등에서 아래의 경로로 접근이 안되므로 변환하지 않는다.
  // 장기적으로는 파일들을 모두 azure storage에 올리는 것이 바람직 할 것.
  //
  // gif
  // 버그인지 의도된 것인지, cloudflare 에서 gif가 avif 형식으로 변환되면
  // anim=false 옵션이 없더라도 정적인 이미지로 변환이 되어버려서, gif는 예외처리
  // 장기적으로는 불필요한 gif 파일을 없애고, video 형식을 사용하는게 좋을 듯.

  // const src = unoptimized ? props.src : cloudflareLoader({ src: props.src, width: rangedWidth(parseInt(String(width), 10)), quality: props.quality || 85 });

  useEffect(()=> {
    if (unoptimizedFromProps === false
      || !src
      || (!src.includes('.acon3d.com') && !src.includes('.cloudfront.net'))
      || ['png', 'gif', 'jpg', 'jpeg'].some(ext => src.includes(`.${ext}`))) return;
    axios.head(src).then((res)=> {
      if (res.headers['content-type'] === 'image/gif') {
        setUnoptimized(true);
      }
    });
  }, [src]);


  const responsiveSizes = unoptimized ? {} : {
    // sizes: sizes ? sizes : width + 'px',
    srcSet: `${cloudflareLoader({ src: props.src, width: rangedWidth(parseInt(String(width), 10)), quality: props.quality || 85 })} 1x, `
    + `${cloudflareLoader({ src: props.src, width: rangedWidth(parseInt(String(width), 10) * 2), quality: props.quality || 85 })} 2x, `
    + `${cloudflareLoader({ src: props.src, width: rangedWidth(parseInt(String(width), 10) * 3), quality: props.quality || 85 })} 3x`,
  };

  if (backgroundColor) style.backgroundColor = backgroundColor;



  return (
    <CustomImageContainer style={style} className="custom-img">
      <img
        ref={imgRef}
        onLoad={(e)=>{
          props.onLoad && props.onLoad(e);
          clearTimeout(timeout);
          (e.target as HTMLImageElement).classList.add('loaded');
        }}
        onError={props.onError}
        onAbort={props.onError}
        className={className}
        {...{ width, height }}
        {...responsiveSizes}
        src={src}
        alt={encodeURIComponent(props.src)}
      />
    </CustomImageContainer>
  );
};

export default CustomImage;
